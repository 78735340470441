$(function () {
    // 課題から探すスライダー
    $('.home-problem-slider .slider').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    variableWidth: true,
    appendArrows: $('.home-problem-slider .slider').siblings('.arrow'),
    prevArrow: '<div class="arrow__prev"><span class="icon"><svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="25" fill="#fff"/><path d="M28 15L18 25l10 10" stroke="#1693D0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg></span></div>',
    nextArrow: '<div class="arrow__next"><span class="icon"><svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="25" transform="rotate(-180 25 25)" fill="#fff"/><path d="M22 35l10-10-10-10" stroke="#1693D0" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg></span></div>',
    responsive: [
        {
        breakpoint: 896,
        settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 3
        }
        },
        {
        breakpoint: 640,
        settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
        }
        }
    ]
    });
    // スタッフスライダー
    $('.home-staff-slider .slider').slick({
    // centerMode: true,
    // centerPadding: '60px',
    slidesToShow: 5,
    // slidesToScroll: 5,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    speed: 5000,
    arrows: false,
    draggable: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
        {
            breakpoint: 896,
            settings: {
                // centerMode: true,
                // centerPadding: '40px',
                slidesToShow: 3
            }
        },
        {
            breakpoint: 640,
            settings: {
                // centerMode: false,
                // speed: 5000,
                // centerPadding: '40px',
                // variableWidth: false,
                // slidesToScroll: 3,
                slidesToShow: 2
        }
        }
    ]
    });
});